import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Controller, Scene } from 'react-scrollmagic';

const About = () => {
  const aboutQueryData = useStaticQuery(graphql`
    query AboutQuery {
      strapiAbout {
        Name
        Description {
          data {
            Description
          }
        }
        Role
        CallToActionButton
        CallToActionLink
        Image {
          localFile {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                srcWebp
                srcSetWebp
                originalImg
                originalName
                src
                srcSet
                sizes
                presentationWidth
                presentationHeight
                aspectRatio
              }
            }
          }
        }
      }
    }
  `);

  const title = aboutQueryData.strapiAbout.Name;
  const Subtitle = aboutQueryData.strapiAbout.Role;
  const description = aboutQueryData.strapiAbout.Description.data.Description;
  const CallToActionButtonText = aboutQueryData.strapiAbout.CallToActionButton;
  const CallToActionLink = aboutQueryData.strapiAbout.CallToActionLink;
  const Image = aboutQueryData.strapiAbout.Image.localFile.childImageSharp.fluid;

  return (
    <div className="rb-about-area about-style rn-section-gap bg-color-white" id="about">
      <div className="container">
        <div className="row row--45 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <div className="trigger" id="trigger2" />
              <Controller>
                <Scene classToggle="animated" triggerElement="#about" triggerHook="onEnter">
                  <div className="rn_surface story-image">
                    <Img className="about-images" fluid={Image} />
                  </div>
                </Scene>
              </Controller>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="inner">
              <div className="content">
                <div className="section-title">
                  <div className="title-wrap">
                    <h3 className="title wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                      {title}
                      <span className="bg">About</span>
                    </h3>
                    {title && (
                      <h4
                        className="subtitle wow fadeInLeft"
                        data-wow-delay="200ms"
                        data-wow-duration="1000ms"
                        dangerouslySetInnerHTML={{ __html: Subtitle }}
                      >
                        {}
                      </h4>
                    )}
                  </div>

                  {description && (
                    <p
                      className="description wow fadeInLeft"
                      data-wow-delay="200ms"
                      data-wow-duration="1000ms"
                      dangerouslySetInnerHTML={{ __html: description }}
                    ></p>
                  )}
                  {false && (
                    <p
                      className="description wow fadeInLeft"
                      data-wow-delay="200ms"
                      data-wow-duration="1000ms"
                      dangerouslySetInnerHTML={{ __html: description2 }}
                    ></p>
                  )}
                </div>
                <div className="button-group mt--30">
                  {CallToActionButtonText && (
                    <a
                      className="rn-button wow fadeInLeft"
                      data-wow-delay="200ms"
                      data-wow-duration="1000ms"
                      href="https://linkedin.com/in/ajla-cano"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>{CallToActionButtonText}</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
