import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Contactform from './contactform';
import GooglemapRn from './googlemap';
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaSkype,
  FaVimeo,
  FaIcons,
  FaMailBulk,
  FaEnvelope,
} from 'react-icons/fa';
import { IconBase } from 'react-icons';

const Contact = () => {
  const contactData = useStaticQuery(graphql`
    query {
      strapiContact {
        Title
        Subtitle
        FirstLink
        SecondLink
        ThirdLink
      }
    }
  `);
  const { Title, Subtitle, FirstLink, SecondLink, ThirdLink } = contactData.strapiContact;

  return (
    <div className="rn-contact-area rn-section-gapBottom pt--200 bg-color-white" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2 className="title">
                {Subtitle}
                <span className="bg">{Title}</span>
              </h2>

              <div className="rn-footer-area">
                <div className="footer-wrapper">
                  <div
                    className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30 wow fadeInDown"
                    data-wow-delay="200ms"
                    data-wow-duration="0.6s"
                  >
                    <div className="container">
                      <div className="row">
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30 wow fadeInDown"
                          data-wow-delay="200ms"
                          data-wow-duration="0.6s"
                        >
                          <div className="ft-info">
                            <ul className="social-share">
                              <li>
                                <a href={FirstLink} target="_blank" rel="noreferrer">
                                  <FaLinkedinIn />
                                </a>
                              </li>
                              <li>
                                <a href={SecondLink} target="_blank" rel="noreferrer">
                                  <FaInstagram />
                                </a>
                              </li>
                              <li>
                                <a href={ThirdLink} target="_blank" rel="noreferrer">
                                  <FaEnvelope />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
