import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Projectcard from './projectcard';
import { slugify } from '../../utils/utilityFunctions';

const ProjectOne = () => {
  const ProjectsQuery = useStaticQuery(graphql`
    query ProjectsQuery {
      allStrapiProject {
        edges {
          node {
            id
            Name
            Type
            Order
            Cover {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 374, maxHeight: 374, quality: 100) {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                    src
                    srcSet
                    sizes
                    presentationWidth
                    presentationHeight
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const projectsData = ProjectsQuery.allStrapiProject.edges;
  projectsData.sort((a, b) => {
    return a.node.Order - b.node.Order;
  });
  return (
    <div className="row row--45 mt_dec--30">
      {projectsData.map((data) => (
        <Projectcard
          key={data.node.id}
          column="col-lg-4 col-md-6 col-12"
          portfolioStyle="portfolio-style-1"
          image={data.node.Cover.localFile.childImageSharp}
          key={data.node.id}
          id={data.node.id}
          title={data.node.Name}
          name={slugify(data.node.Name)}
          category={data.node.Type}
        />
      ))}
    </div>
  );
};

export default ProjectOne;
