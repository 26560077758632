import React from 'react';
import { RiTreasureMapLine, RiStarSmileLine } from 'react-icons/ri';
import { RxRocket } from 'react-icons/rx';

import { useStaticQuery, graphql } from 'gatsby';

const Service = () => {
  const FocusAreasQueryData = useStaticQuery(graphql`
    query FocusAreas {
      allStrapiFocusArea {
        edges {
          node {
            Icon
            Title
            Description
          }
        }
      }
    }
  `);

  const focusAreas = FocusAreasQueryData.allStrapiFocusArea.edges;

  // TODO: Fix Icon
  const FocusArea = ({ node }) => {
    const { Title, Description, Icon } = node;
    const icon1 = <RiStarSmileLine />;
    const icon2 = <RiTreasureMapLine />;
    const icon3 = <RxRocket />;
    let icon;

    switch (Icon) {
      case 'icon1':
        icon = icon1;
        break;
      case 'icon2':
        icon = icon2;
        break;
      case 'icon3':
        icon = icon3;
        break;
    }

    return (
      <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
          <div className="inner">
            <div className="icon">{icon}</div>
            <div className="content">
              <h4 className="title">{Title}</h4>
              <p>{Description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="rn-service-area rn-section-gapBottom">
      <div className="rn-service-area">
        <div className="container">
          <div className="row">
            {focusAreas.map(({ node }) => (
              <FocusArea node={node} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
